@import 'assets/css/constants.scss';

.requests {
  height: calc(100vh - $topbar-height - $toolbar-height);
  width: 100%;
  padding: 18px 24px;
  position: relative;

  .list {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .request {
      display: flex;
      align-items: center;
      background-color: #F6F6F6;
      height: 80px;
      border-radius: 8px;
      padding-right: 8px;

      &.head {
        padding-left: 66px;
        background-color: #212731;
        color: white;
      }

      // excel download button
      > button:first-of-type {
        width: 66px;
        font-size: 9px;
        flex-direction: column;
        gap: 2px;
        flex-shrink: 0;
        
        svg {
          margin-right: 0;
        }
      }

      > p {
        padding: 16px;
        flex-shrink: 0;

        &.name {
          width: 200px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &.status {
          width: 135px;
        }

        &.date {
          width: 110px;
        }
      }

      .actions-status {
        width: 100%;
        display: flex;
        gap: 12px;
      }

      .export-info {
        height: 56px;
        display: flex;
        width: 100%;
        background: #f0f3f8a4;
        border-radius: 12px;
        border: 1px solid #5c8cc6b1;
        align-items: center;
        justify-content: space-around;

        p, button {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        p.progress {
          width: 100px;
          text-align: center;
        }

        &.error, &.success {
          justify-content: unset;
          padding: 0 8px;

          p {
            margin: 0 auto;
          }
        }

        &.error {
          color: white;
          fill: white;
          background: #ec3c41;
          border-color: #DC0D15;

          button {
            color: black;
            background: white;
          }
        }

        &.success {
          background: #43CF7B;
          border-color: #2EBD6A;

          button {
            color: black;
            background: white;
          }
        }
      }

      .actions-request {
        display: flex;
        align-items: center;
        width: 68px;
        flex-shrink: 0;
        margin-left: 10px;

        button {
          width: 36px;
          height: 36px;

          svg {
            margin: 0;
          }
        }

        button:last-of-type svg * {
          fill: rgb(190, 32, 32);
        }
      }

      &.success {
        background-color: #D9F5E4;
        border: 1px solid #43CF7B;
      }

      &.error {
        background-color: #FDE8E8;
        border: 1px solid #EC3C41;
      }
    }
  }
}